import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStoreService } from './../store/app-store.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private readonly _appStoreService: AppStoreService,
    ) {}

    intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        const token = this._appStoreService.token;

        // TODO
        // exclude more API urls
        const isApiUrl = 
            request.url.indexOf('/verify/') === -1
            && request.url.indexOf('/send-password-reminder') === -1
            && request.url.indexOf('/register') === -1
            && request.url.indexOf('/login') === -1 
            && request.url.indexOf('/change-password') === -1
            && request.url.indexOf('http://agroit-dev.molaris.local:5601/') === -1
        ;

        if (token && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token.token}`,
                },
            });
        }

        return next.handle(request);
    }
}
