import { Component, DoCheck, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { UnsubscribeOnDestroyAdapter } from '../../unsubscribe-on-destroy-adapter';
import { environment } from '../../../../environments/environment';
import { AppStoreService } from '../../../core/store/app-store.service';

@Component({
    selector: 'app-app-version',
    templateUrl: './app-version.component.html',
    styleUrls: ['./app-version.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
    ],
})
export class AppVersionComponent extends UnsubscribeOnDestroyAdapter implements OnInit, DoCheck{
    backendVersion$: Observable<string>;
    backendHost$: Observable<string>;
    backendBuildTime$: Observable<string>;
    frontendVersion = environment.appVersion;
    frontendSha1 = environment.gitSha1;
    frontendBuildNo = environment.buildNumber;
    showVersionInfo = environment.showVersionInfo;

    menuCollapsedValue$ = new BehaviorSubject<boolean>(this.menuCollapsed);

    constructor(
        private readonly _appStoreService: AppStoreService,
    ) {
        super();
        this.backendVersion$ = this._appStoreService.backendVersion$;
        this.backendHost$ = this._appStoreService.backendHost$;
        this.backendBuildTime$ = this._appStoreService.backendBuildTime$;
    }

    ngOnInit(): void {
        this.createSubscriptions();
    }

    ngDoCheck() {
        if(this.menuCollapsed !== this.menuCollapsedValue$.getValue()){
            this.menuCollapsedValue$.next(this.menuCollapsed);
        }
    }

    private createSubscriptions(): void {}

    get menuCollapsed(): boolean {
        return localStorage.getItem('collapsed_menu') === 'true';
    }
}
