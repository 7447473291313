import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core';
import { httpInterceptorProviders } from './core/interceptor';
import { AppInitService } from './core/service/app-init.service';
import { WINDOW_PROVIDERS } from './core/service/window.service';
import { SharedModule } from './shared';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { provideAnimations } from '@angular/platform-browser/animations';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
  
export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.init();
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withComponentInputBinding()),
        importProvidersFrom(
            BrowserModule, 
            LoadingBarRouterModule, 
            NgScrollbarModule, 
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
            }), 
            SharedModule, 
            CoreModule,
        ),
        httpInterceptorProviders,
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
        WINDOW_PROVIDERS,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ]
};
