import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, mergeMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { QrService } from '../services/qr.service';
import { IpResponse } from '../../../core/models/api/ip-response.model';
import { IpifyApiService } from '../../../core/service/api/ipify/ipify-api-service';
import { QrApiService } from '../../../core/service/api/qr/qr-api.service';
import { ResolveQrRequest } from '../../../core/models/api/resolve-qr-request.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductDisplayResponse } from '../../../core/models/api/product-display-response.model';

@Injectable({ 
    providedIn: 'root'
})
export class QrResolver{
    constructor(
        private readonly _qrService: QrService,
        private readonly _ipifyApiService: IpifyApiService,
        private readonly _qrApiService: QrApiService,
        private readonly _deviceDetectorService: DeviceDetectorService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductDisplayResponse | HttpErrorResponse> | Error {
        const uniqueId = route.paramMap.get('uniqueId');
        if(uniqueId != null){
            return this.getData(uniqueId);
        }

        this._qrService.setLoading(false);
        return new Error('Hibás azonosító kód!');
    }

    private getData(uniqueId: string): Observable<ProductDisplayResponse | HttpErrorResponse> {
        // console.dir(this._deviceDetectorService);
        const resolveQrRequest: ResolveQrRequest = {
            uniqueId,
            platform: `${this._deviceDetectorService.os}/${this._deviceDetectorService.os_version}`, // kötelező
            browser: `${this._deviceDetectorService.browser}/${this._deviceDetectorService.browser_version}` , // kötelező
            windowWidth: window.screen.width, // kötelező
            windowHeight: window.screen.height, // kötelező
        }

        return this.getIpJson().pipe(
            mergeMap(response => {
                resolveQrRequest.ip = response.ip;
                return this.postQrResolve(resolveQrRequest);
            }),
            catchError((error: HttpErrorResponse) => {
                return this.postQrResolve(resolveQrRequest);
            })
        );
    }
    
    private getIpJson(): Observable<IpResponse> {
        return this._ipifyApiService.getIpJson();
    }
    
    private postQrResolve(resolveQrRequest: ResolveQrRequest): Observable<ProductDisplayResponse> {
        return this._qrApiService.postQrResolve(resolveQrRequest);
    }
}
