import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AppStoreService } from '../store/app-store.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(
    private readonly _appStoreService: AppStoreService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        // Skip request
        if (httpEvent.type === 0) {
          return;
        }

        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.headers.has('backend-version')) {
            this._appStoreService.setBackendVersion(
              httpEvent.headers.get('backend-version')
            );
          }
          if (httpEvent.headers.has('backend-host')) {
            this._appStoreService.setBackendHost(
              httpEvent.headers.get('backend-host')
            );
          }
          if (httpEvent.headers.has('backend-build-date')) {
            this._appStoreService.setBackendBuildTime(
              httpEvent.headers.get('backend-build-date')
            );
          }
        }
      })
    );
  }
}
