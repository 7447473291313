<!-- TODO: bizonyos esetekben (sok menüpont, fekvő mobil) összecsúszhat a menüvel -->
<div class="app-version-container" *ngIf="!(menuCollapsedValue$ | async)">
    <div *ngIf="showVersionInfo" class="app-versions">
        <div class="app-version-frontend-version">
            FE verzió: {{ frontendVersion }}
        </div>
        <!-- <div class="app-version-frontend-build">
            Jenkins No.: {{ frontendBuildNo }}
        </div> -->
        <!-- <div class="app-version-frontend-sha1">
            E SHA1: {{ frontendSha1 }}
        </div> -->
        <div class="app-version-backend-version" *ngIf="backendVersion$ | async as backendVersion">
            BE verzió: {{ backendVersion }}
        </div>
        <!-- <div class="app-version-backend-version">
            BE Build idő: {{ backendBuildTime$ | async }}
        </div> -->
        <!-- <div class="app-version-backend-host">
            {{ backendHost$ | async }}
        </div> -->
    </div>

</div>
