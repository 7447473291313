import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../core/service/auth.service';

@Component({
  template: '',
})
export class LogoutComponent implements OnInit, OnDestroy {
  constructor(
    private _authService: AuthService,
  ) {}

  ngOnInit() {
    this._authService.logout('Sikeresen kijelentkezett.', true); // USER_LOGOUT
  }

  ngOnDestroy() {}
}
