import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { PermissionTypeService } from '../service/permission-type.service';
import { PermissionType } from '../enums/permission-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private readonly _authService: AuthService,
    private readonly _permissionTypeService: PermissionTypeService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissionType = next.data && next.data['requiredPermissionTypes'] ? (next.data['requiredPermissionTypes'] as PermissionType[]) : undefined;
    const result = this._permissionTypeService.userHasAllPermissionTypes(permissionType);
    if (!result) {
      this._authService.logout('Nincs elegendő jogosultsága a kívánt művelethez!'); // MISSING_REQUIRED_PERMISSIONS
    }
    return result;
  }
}
