import { Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { PermissionGuard } from './core/guard/permission.guard';
import { LogoutComponent } from './features/logout/logout.component';
import { Page404Component } from './features/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { RouteActionMode } from './core/enums/route-action-mode.enum';
import { QrPreviewResolver } from './features/qr-preview/resolvers/qr-preview.resolver';
import { QrResolver } from './features/qr/resolvers/qr.resolver';
import { PermissionType } from './core/enums/permission-type.enum';

export const routes: Routes = [
    // { 
    //     path: '', redirectTo: '/login', pathMatch: 'full'
    // },
    {
        path: '',
        component: MainLayoutComponent,
        // data: {
        //     permissionType: 'LOGIN',
        // },
        canActivate: [AuthGuard, PermissionGuard],
        children: [
            { 
                path: '', redirectTo: '/login', pathMatch: 'full'
            },
            {
                path: 'products',
                data: {
                    requiredPermissionTypes: [],
                },
                loadChildren: () => import('./features/products/products.routes').then(m => m.routes),
                canActivate: [PermissionGuard],
            },
            {
                path: 'statistics',
                data: {
                    requiredPermissionTypes: [
                        PermissionType.STAT_VIEWER,
                    ],
                },
                loadChildren: () => import('./features/statistics/statistics.routes').then(m => m.routes),
                canActivate: [PermissionGuard],
            },
        ],
    },
    {
        path: 'qr-preview/:productDetailUuid',                
        data: {
            routeActionMode: RouteActionMode.VIEW,
        },
        loadComponent: () => import('./features/qr-preview/qr-preview.component').then((m) => m.QrPreviewComponent),
        resolve: { resolverData: QrPreviewResolver },
        canActivate: [PermissionGuard],
    },
    {
        path: 'qr/:uniqueId',                
        data: {
            routeActionMode: RouteActionMode.VIEW,
        },
        loadComponent: () => import('./features/qr/qr.component').then((m) => m.QrComponent),
        resolve: { resolverData: QrResolver },
    },
    {
        path: 'login',
        component: AuthLayoutComponent,
        loadChildren: () => import('./features/login/login.routes').then((m) => m.routes),
    },
    // {
    //     path: 'iframe',                
    //     loadComponent: () => import('./features/iframe/iframe.component').then((m) => m.IframeComponent),
    // },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    { 
        path: '**', 
        component: Page404Component,
    },
];
