import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionTypeService } from '../../../core/service/permission-type.service';
import { RouteActionMode } from '../../../core/enums/route-action-mode.enum';
import { ProductDetailApiService } from '../../../core/service/api/product-detail/product-detail-api.service';
import { ProductDisplayResponse } from '../../../core/models/api/product-display-response.model';

@Injectable({ 
    providedIn: 'root'
})
export class QrPreviewResolver{
    constructor(
        private readonly _productDetailApiService: ProductDetailApiService,
        private readonly _permissionTypeService: PermissionTypeService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductDisplayResponse | HttpErrorResponse> | Error {
        const productDetailUuid = route.paramMap.get('productDetailUuid');
        if(
            this._permissionTypeService.userHasPermissionType(null)  // TODO: set the required PermissionType
            && typeof route.data['routeActionMode'] !== 'undefined' 
            && route.data['routeActionMode'] === RouteActionMode.VIEW
            && productDetailUuid != null
        ){
            return this.getData(productDetailUuid);
        }
        
        if(productDetailUuid == null){
            // no productDetailUuid
            return new Error('Nincs ilyen termék adatlap!');
        }
        
        // no permision
        return new Error('Nincs elegendő jogosultsága a kívánt művelethez!');
    }

    private getData(productDetailUuid: string): Observable<ProductDisplayResponse | HttpErrorResponse> {
        return this.getProductDetailFindProductDetailUuid(productDetailUuid).pipe(catchError((error: HttpErrorResponse) => of(error)));
    }

    private getProductDetailFindProductDetailUuid(productDetailUuid: string): Observable<ProductDisplayResponse>{
        return this._productDetailApiService.getProductDetailFindProductDetailUuid(productDetailUuid);
    }
}
