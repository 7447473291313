<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form">
        <span class="error-header p-b-45">
          404
        </span>
        <span class="error-subheader p-b-5">
          Úgy tűnik, eltévedt
        </span>
        <span class="error-subheader2 p-b-5">
          Az Ön által keresett oldal nem elérhető!
        </span>
        <div class="container-login100-form-btn p-t-30	">
          <button mat-raised-button color="primary" class="login100-form-btn" (click)='submit()'>
            Vissza a Főoldalra
          </button>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/pages/bg-03.png');">
      </div>
    </div>
  </div>
</div>
