import { Component, OnInit } from '@angular/core';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { LoaderService } from './core/service/loader.service';
import { UnsubscribeOnDestroyAdapter } from './shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet, 
        SpinnerComponent,
    ]
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
    constructor(
        private readonly _loaderService: LoaderService,
        private readonly _router: Router,
    ){
        super();
    }

    ngOnInit(): void {
        this.createSubscriptions();
    }

    private createSubscriptions() {
        this._subscription.add(
            this._router.events.subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationStart) {
                  this._loaderService.setLoading(true, routerEvent.url);
                }

                if (
                    routerEvent instanceof NavigationEnd 
                    || routerEvent instanceof NavigationCancel
                    || routerEvent instanceof NavigationError
                    || routerEvent instanceof NavigationSkipped
                ) {
                    this._loaderService.setLoading(false, routerEvent.url);
                }
            })
        );
    }
}
