import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { delay, lastValueFrom, of } from 'rxjs';
import { SessionKey } from '../enums/session-key.enum';
import { Token } from '../models/token.model';
import { AppStoreService } from '../store/app-store.service';
import { AuthService } from './auth.service';
import { LanguageService } from './language.service';
import { SessionService } from './session.service';
import { unixTimeStamp } from '../utils/date-time';
import { StorageType } from '../enums/storage-type.enum';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppInitService {
    constructor(
        private readonly _sessionService: SessionService,
        private readonly _appStoreService: AppStoreService,
        private readonly _translate: TranslateService,
        private readonly _authService: AuthService,
        private readonly _languageService: LanguageService,
        private readonly _http: HttpClient,
    ) {}

    async init(): Promise<void> {
        this._sessionService.startSession();
        await this.initTranslate();
        // const ipAddress = await this.retrieveIpAddress();
        const storageValue = this._sessionService.getItem(SessionKey.TOKEN);
        
        if (storageValue) {
            try {
                let token: Token = jwtDecode<any>(storageValue);
                
                if (token != null && token.exp <= unixTimeStamp()) {
                    this._authService.logout('Lejárt a munkamenete. Jelentkezzen be újra!'); // TOKEN_EXPIRED
                }
            } catch (error) {
                this._authService.logout('Hibás bejelentkezési azonosító. Próbáljon bejelentkezni újra!'); // TOKEN_ERROR
            }
        }
        // await this.spinnerTest(10000);
    }

    private async initTranslate(): Promise<void> {
        try {
            // await this.getLanguages();
            this._appStoreService.setLanguageList(['hu']); // most csak magyar

            this._translate.addLangs(this._appStoreService.languageList);
            // // a böngésző nyelvéhez próbál igazodni
            // const browserLang = this._translate
            //   .getLangs()
            //   .includes(this._translate.getBrowserLang())
            //   ? this._translate.getBrowserLang()
            //   : 'hu';
            const browserLang = 'hu'; // így mindig magyar lesz
            const lang = this._sessionService.getItem(SessionKey.LANG) ?? browserLang;

            this._translate.setDefaultLang(lang!);
            this._translate.use(lang!);
            this._sessionService.setItem(SessionKey.LANG, lang!, StorageType.LOCAL);
            this._appStoreService.setLang(lang!);
        } 
        catch (error) {}
    }

    private async getLanguages(): Promise<string[]> {
        return lastValueFrom(this._languageService.getLanguages())
            .then(
                (languageList: string[]) => {
                    this._appStoreService.setLanguageList(languageList);
                    return Promise.resolve(languageList);
                }
            )
            .catch((error) => {
                return Promise.reject();
            });
    }

  // ---------------------------------------------------------------------

  // async initUserData(uuid: string): Promise<void> {
  //   await this.getUserByIdPromise(uuid)
  //     .then(async (user: UserDto) => {
  //       this.appStoreService.setUser(user);
  //     })
  //     .catch((error) => {});
  // }

  // private getUserByIdPromise(uuid: string): Promise<UserDto> {
  //   return lastValueFrom(this.userApiService.getUserByUuid(uuid))
  //     .then(function (user: UserDto) {
  //       return Promise.resolve(user);
  //     })
  //     .catch((error) => {
  //       return Promise.reject();
  //     });
  // }

    private async retrieveIpAddress(): Promise<any> {
        return lastValueFrom(this._http.get('http://api.ipify.org/?format=json'))
            .then(function (response: any) {
                return Promise.resolve(response.ip);
            })
            .catch((error) => {
                //   return Promise.reject();
                return Promise.resolve('');
            });
    }

    private async spinnerTest(ms: number): Promise<string>{
        return lastValueFrom(
            of('spinner test').pipe(
                delay(ms)
            ))
                .then(function (text: string) {
                    return Promise.resolve(text);
                });
    }
}
