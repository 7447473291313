import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTimeoutInterceptor } from './http-timeout.interceptor';
import { JwtInterceptor } from './jwt.interceptor';
import { VersionInterceptor } from './version.interceptor';
// import { LoadSpinnerInterceptor } from './load-spinner.interceptor';
// import { HttpErrorInterceptor } from './http-error.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: LoadSpinnerInterceptor, multi: true }, // not in use, load spinner is controlled by router events in AppComponent
  // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
];
