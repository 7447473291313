import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: string[] = ['hu', 'en'];

  constructor(
    public translate: TranslateService,
    private readonly _http: HttpClient
  ) {
    let browserLang: string;
    translate.addLangs(this.languages);

    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang') as string;
    } else {
      browserLang = translate.getBrowserLang() as string;
    }
    translate.use(browserLang.match(/hu|en/) ? browserLang : 'en');
  }

  public setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  // getLanguage(cc: string): Observable<any> {
  //   let url = 'assets/i18n/{cc}.json';
  //   url = url.replace('{cc}', cc);
  //   return this.http.get<any>(url);
  // }

  getLanguages(): Observable<string[]> {
    const url = 'assets/data/lang-list-all.json';
    return this._http.get<string[]>(url);
  }
}
