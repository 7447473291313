import { Injectable, Input } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard { 
  constructor(
    private readonly _authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const result = this._authService.isAuthenticated;
    if (!result) {
      this._authService.logout('A kért művelethez először be kell jelentkeznie!'); // NOT_AUTHENTICATED
    }
    return result;
  }
}
