import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { timeout } from 'rxjs/operators';


@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url === 'http://api.ipify.org/?format=json') {
            return next.handle(request).pipe(timeout(2000)); // max 2 sec waiting for the IP address
        }
        return next.handle(request).pipe(timeout(env.api.timeout));
    }
}
