import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ipifyApi } from '../../../../backend/ipify.api';
import { IpResponse } from '../../../models/api/ip-response.model';

@Injectable({
    providedIn: 'root',
})
export class IpifyApiService  {
    constructor(
        private readonly _http: HttpClient
    ) {}

    // private async retrieveIpAddress(): Promise<any> {
    //     return lastValueFrom(this._http.get('http://api.ipify.org/?format=json'))
    //       .then(function (response: any) {
    //         return Promise.resolve(response.ip);
    //       })
    //       .catch((error) => {
    //       //   return Promise.reject();
    //         return Promise.resolve('');
    //       });
    // }

    getIpJson(): Observable<IpResponse> {
        return this._http.get<IpResponse>(`${ipifyApi.getIpJson}`);
    }
}
